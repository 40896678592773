/* FONT IMPORTS */

@import url('https://fonts.googleapis.com/css?family=Open + Sans:300, 400, 700');
@import url('https://fonts.googleapis.com/icon?family=Material + Icons');

.icon {
  font-family: 'Lato';
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --main-bg-color: #e0f0fe;
  --text-color: #777;
  --text-color-light: #a5a3a3;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

.weekly-calendar-weekly-calendar-body {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
}

.weekly-calendar-header {
  display: block;
  width: 100%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--main-bg-color);
}

.weekly-calendar-header #logo {
  font-size: 16px;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

.weekly-calendar-header #logo .icon {
  padding-right: 0.25em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 1.25rem;
}

.calendar .weekly-calendar-header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .weekly-calendar-header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .weekly-calendar-header .icon:hover {
  /*transform: scale(1.75);*/
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .weekly-calendar-header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .weekly-calendar-header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 14px;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .weekly-calendar-body .cell {
  position: relative;
  height: 7.5em;
  border-right: 1px solid var(--border-color);
  overflow: scroll;
  cursor: pointer;
  background: var(--neutral-bg-color);
  transition: 0.25s ease-out;
  font-size: 1.5em;
}

.calendar .weekly-calendar-body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .weekly-calendar-body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}
.calendar .weekly-calendar-body .today {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
  border-image-slice: 1;
}
.calendar .weekly-calendar-body .row {
  border-bottom: 1px solid var(--border-color);
}

/* .calendar .weekly-calendar-body .row:last-child {
    border-bottom: none;
  } */

.calendar .weekly-calendar-body .cell:last-child {
  border-right: none;
}

.calendar .weekly-calendar-body .cell .number {
  position: absolute;
  font-size: 15px;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.day-button {
  background: transparent;
  border: none;
}

.day-appointment-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1.5em;
  left: 5px;
}

.appointment-name {
  font-size: 15px;
  text-align: left;
  line-height: 16px;
  margin-bottom: 5px;
  padding: 3px;
  color: red;
  background-color: rgb(252, 241, 240);
}

.appointment-name.appointment-name-purple {
  color: purple;
  background-color: rgb(252, 240, 252);
}

.appointment-name.appointment-name-darkBlue {
  color: #261bf4;
  background-color: rgb(207, 224, 247);
}

.appointment-name.appointment-name-tiffany {
  color: #0abab5;
  background-color: rgb(197, 242, 240);
}

.appointment-name.appointment-name-orange {
  color: #fbae15;
  background-color: rgb(248, 225, 208);
}

.appointment-name.appointment-name-green {
  color: #29b974;
  background-color: rgb(210, 247, 203);
}

.appointment-name.appointment-name-gold {
  color: #d3ab29;
  background-color: #faf5d4;
}

.calendar .weekly-calendar-body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .weekly-calendar-body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .weekly-calendar-body .cell:hover .bg,
.calendar .weekly-calendar-body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}
.calendar .weekly-calendar-body .cell.today .bg {
  color: #ff1a79;
  opacity: 0.05;
}

.calendar .weekly-calendar-body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.weekBtn {
  border: none;
  background-color: transparent;
  padding: 5px;
  margin-left: 1em;
  margin-right: 1em;
  cursor: pointer;
}
