// https://github.com/wojtekmaj/react-calendar#custom-styling
.react-calendar {
  font-family: 'Lato';
  font-size: '12px';
  border: none;
  min-width: 260px;
  max-width: 260px;
  height: max-content;

  button.react-calendar__month-view__days__day {
    margin: 0.1% 1.05px;
  }
}

.react-calendar__navigation {
  margin-bottom: 0;
}

.react-calendar__navigation button {
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: white;
  color: #2d42b6;
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
  color: #646464;
  size: 13px;
}

.react-calendar__month-view__days__day--weekend {
  color: inherit;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 0.5em;
}
.react-calendar__tile.react-calendar__month-view__days__day {
  min-width: 35px;
  max-width: 35px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-align: center;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile--hasActive,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #eceff9;
}
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #2d42b6;
  color: white;
}

.react-calendar__tile--now {
  background-color: #e1e1e1;
  color: inherit;
}

.react-calendar__tile--now.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #2d42b6;
  color: white;
}

.dot__indicator {
  display: inline-block;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 2.8px;
    height: 2.8px;

    position: absolute;
    bottom: 5px;
  }
}

.dot__indicator-white {
  &:before {
    background-color: white;
  }
}

.dot__indicator-gray {
  &:before {
    background-color: gray;
  }
}
